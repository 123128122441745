<template>
    <div
        class="rounded-md p-4"
        :class="bgClass">
        <div class="flex items-center">
            <div
                v-if="!['info', 'action'].includes(props.type) "
                class="flex-shrink-0">
                <Icon
                    name="heroicons:exclamation-circle-20-solid"
                    :class="iconClass" />
            </div>
            <div class="ml-3">
                <h3
                    v-if="props.title"
                    class="text-sm font-medium mb-2"
                    :class="titleClass">
                    {{ props.title }}
                </h3>
                <div
                    v-if="props.text || $slots.default"
                    class="text-sm"
                    :class="textClass">
                    <p>
                        {{ props.text }}
                        <slot />
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: '',
    },
    text: {
        type: String,
        default: ''
    },
    type: {
        type: String,
        default: 'warning'
    },
})

const bgClass = computed(() => {
    switch (props.type) {
    case 'warning':
        return 'bg-yellow-50'
    case 'error':
        return 'bg-red-50'
    case 'success':
        return 'bg-green-50'
    case 'info':
        return 'bg-gray-50'
    case 'action':
        return 'bg-primary-50'
    default:
        return 'bg-yellow-50'
    }
})

const titleClass = computed(() => {
    switch (props.type) {
    case 'warning':
        return 'text-yellow-800'
    case 'error':
        return 'text-red-800'
    case 'success':
        return 'text-green-800'
    case 'info':
        return 'text-gray-700'
    case 'action':
        return 'text-primary-800'
    default:
        return 'text-yellow-800'
    }
})

const textClass = computed(() => {
    switch (props.type) {
    case 'warning':
        return 'text-yellow-700'
    case 'error':
        return 'text-red-700'
    case 'success':
        return 'text-green-700'
    case 'info':
        return 'text-gray-600'
    case 'action':
        return 'text-primary-700'
    default:
        return 'text-yellow-700'
    }
})

const iconClass = computed(() => {
    switch (props.type) {
    case 'warning':
        return 'text-yellow-400'
    case 'error':
        return 'text-red-400'
    case 'success':
        return 'text-green-400'
    case 'info':
        return 'text-gray-400'
    case 'action':
        return 'text-primary-400'
    default:
        return 'text-yellow-400'
    }
})
</script>